<template>
  <section class="cont">
    <el-row class="crumbs-box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
         <el-breadcrumb-item>PMS</el-breadcrumb-item>
        <el-breadcrumb-item>价格管理</el-breadcrumb-item>
        <el-breadcrumb-item>房价策略</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
   <el-row class="content-box">
      <!-- 查询框 -->
      <el-row class="search-box">
         <el-row class="search-row">
            <el-row class="search-item">
               <label>策略名称：</label>
               <el-input class="width-220"  placeholder="策略名称" v-model="name" clearable></el-input>
            </el-row>
            <!-- 暂时注释 -->
            <!-- <el-row class="search-item">
               <label>策略状态：</label>
               <el-select class="width-220"  v-model="state" :placeholder="$t('msg.select')">
                  <el-option
                        v-for="item in strategyStateList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                  </el-option>
               </el-select>
            </el-row> -->
           <el-row class="search-item">
              <label>生效时间：</label>
              <el-date-picker  class="width-220"  v-model="takeffectime" type="date" placeholder="选择日期" ></el-date-picker>
           </el-row>
            <el-row class="search-item">
               <el-button class="bg-gradient" type="primary" style="margin-left: 10px" @click="searchEvnet(true)"><span>搜索</span></el-button>
               <el-button  @click="searchEvnet(false)"><span>重置</span></el-button>
            </el-row>
         </el-row>
      </el-row>
      <!-- 主体内容 -->
      <el-row class="table-box">
         <!-- 表格按钮 -->
         <div class="table-head-btn">
            <el-row></el-row>
            <el-row class="right-btn">
               <el-button class="bg-gradient"  type="primary" @click="addEditEvnet('add')"><span>新增房价策略</span></el-button>
            </el-row>
         </div>
             <!-- 表格 -->
            <el-table
                  v-loading="loading"
                  :data="tableData"
                  border fit
                  :stripe="true"
                  >
               <el-table-column  prop="id" label="策略编号" min-width="30"></el-table-column>
               <el-table-column  prop="name" label="策略名称" min-width="30"></el-table-column>
               <el-table-column  label="有效时间" min-width="30">
                  <template slot-scope="scope">
                     <div>{{getTime(scope.row.beginTime)}} - {{getTime(scope.row.endTime)}}</div>
                     <div v-if="timeShow(scope.row)" class="weeksBox">
                        每周 {{scope.row.weeklist}} 有效
                     </div>
                  </template>
               </el-table-column>
               <!-- 暂时注释 -->
               <!-- <el-table-column label="状态" min-width="30" >
                  <template slot-scope="scope">
                    <span>
                      {{ scope.row.state === "INVALID" ? "未生效" :  scope.row.state === "VALID" ? "有效" : scope.row.state === "EXPIRED" ? "已过期" : scope.row.state === "STOP" ? "停用" : ""}}
                    </span>
                  </template>
               </el-table-column> -->
               <el-table-column  label="操作"  min-width="30" >
                  <template slot-scope="scope">
                     <el-button type="text"  @click="addEditEvnet('check',scope.row)">
                        <el-link type="primary">查看</el-link>
                     </el-button>
                     <el-button type="text"   v-if="scope.row.state != 'STOP' " @click="addEditEvnet('copy',scope.row)">
                        <el-link type="primary" >复制</el-link>
                     </el-button>
                     <el-button type="text"  v-if="scope.row.state == 'VALID' &&  scope.row.state != 'STOP'" @click="addEditEvnet('edit',scope.row)">
                        <el-link type="primary"  >编辑</el-link>
                     </el-button>
                     <!-- 暂时注释 -->
                     <!-- <el-button type="text"   v-if="scope.row.state== 'VALID' "  @click="handleCancelEna('STOP',scope.row.id)" >
                        <el-link type="primary" >停用</el-link>
                     </el-button>
                     <el-button type="text"  v-if="scope.row.state != 'VALID' "   @click="handleCancelEna('VALID',scope.row.id)"  >
                        <el-link type="primary" >启用</el-link>
                     </el-button> -->
                  </template>
               </el-table-column>
            </el-table>
           <!-- 分页 -->
           <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
      </el-row>
    </el-row>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { urlObj } from '@/api/interface'
import { pms } from '@/api/interface/pms'
export default {
  data(){
    return{
      hotelId:'',      // 酒店id
      state:"",        // 策略状态
      takeffectime:"", // 生效时间
      name:'',         // 策略名称
      strategyStateList: [
        { label: '全部', value: '' },
        { label: '未生效', value: 'INVALID' },
        { label: '有效', value: 'VALID' },
        { label: '已过期', value: 'EXPIRED' },
        { label: '停用', value: 'STOP' },
      ],

      // 表格数据
      tableData: [],
      loading: true,           // 表格加载效果

      total: 0,                  // 用户列表总条目数
      page: 1,                   // 当前页 默认第一页
      limit:10,                  // 每页显示数
      // isShow: false,             // 是否显示弹窗
      titleDia:'查看',              //弹窗title
      labelWidth:'120px',
    }
  },
   computed: {
      ...mapState(['hotelInfo'])
   },
  mounted() {
    this.limit = sessionStorage.getItem('pageSize') * 1
    this.getInitial();
  },
  methods: {
    //判断是否显示每星期几有效
    timeShow(row){
      if(row.mon == 1 || row.tue == 1 || row.wed == 1 || row.thu == 1 || row.fri == 1 || row.sat == 1 || row.sun == 1 ){
        return true
      }else{
        return  false
      }
    },
    // 获取房价策略 表格信息
    getInitial() {
      const url = pms.getHPStrategy + `?limit=${this.limit}&page=${this.page}`
      const param = {
        hotelId: this.hotelInfo.id,
        state: this.state,
        takeffectime: this.takeffectime,
        name: this.name
      }
      this.$axios.post(url, param).then((res) => {
        if (res.success) {
            this.total = res.total
            this.tableData = res.records
            this.loading = false
            this.tableData.forEach(item=>{
               item.weeklist = []
               if (item.mon == 1) item.weeklist.push('周一')
               if (item.tue == 1) item.weeklist.push('周二')
               if (item.wed == 1) item.weeklist.push('周三')
               if (item.thu == 1) item.weeklist.push('周四')
               if (item.fri == 1) item.weeklist.push('周五')
               if (item.sat == 1) item.weeklist.push('周六')
               if (item.sun == 1) item.weeklist.push('周日')
               item.weeklist = item.weeklist.join('、')
           })
        }
      })
    },
    // 搜索/重置
    searchEvnet(bool){
      if (bool) {
        this.page = 1
        this.getInitial()
      }else {
        this.state = ''
        this.name = ''
      }
    },
    // 新增/编辑/复制
    addEditEvnet(state,item){
      sessionStorage.setItem('strategyinfo',JSON.stringify(item))
      this.$router.push({
        path:"/add_house_price_strategy",
        query: {roomAddEdit:state}
      })
    },


    //时间戳转为时间
    getTime(oitem){
      let myDate = new Date(oitem);
      let year =  (myDate.getFullYear()).toString().padStart(2, '');
      let month = (myDate.getMonth() + 1).toString().padStart(2, '');
      let date = myDate.getDate().toString().padStart(2, '');
      return  year + '/' + month + '/' + date
    },
    //启用/停用
    handleCancelEna(state,row){
       let titles = state === "STOP" ? "停用" : "启用"
        this.$alert('是否确定'+ titles,titles, {
          confirmButtonText: '确定',
          callback: action => {
             const url = pms.housePriceStop
             const param = {
               id:row,
               state:state,
             }
             this.loading = true;
             this.$axios.post(url, param).then((res) => {
               this.loading = false;
               if (res.success) {
                 this.getInitial()
                 this.$message.success('修改成功')
               }
             })
          }
       });
    },
    // 改变每页数
    pageChange(num) {
      this.limit = num
      this.getInitial()
    },
    // 改变当前页
    handlePaging(num) {
      this.page = num
      this.getInitial()
    },
  },
   watch: {
      hotelInfo: {
         handler(newVal, oldVal) {
            if (newVal.id !== oldVal.id && oldVal) {
               this.getInitial();
            }
         },
         deep: true
      }
   }
}
</script>

<style scoped lang="scss">
/deep/ .el-table th.gutter{
  /*
  * 解决element-ui 表格篡位的问题 👇
  */
  display: table-cell!important;
}
.weeklist{
   border-bottom: 1px solid;color: #2d2d2d;
}
///deep/ .el-table--enable-row-transition .el-table__body td{
//  text-align: center;
//}
///deep/ .el-table th.is-leaf, .el-table td{
//  text-align: center;
//  background: #f5f8f9;
//}
.weeksBox{
  span{
    margin: 0px 1px;
  }
}
</style>
